<template>
	<div class="d-flex w-100">
		<div class="d-flex align-items-center flex-fill">
			<h6 v-if="totalSelect !== null" class="m-0 typo-body-2">
				{{ totalSelect }} Selected
			</h6>
			<div v-if="isShowClear" class="clear-action ml-3 pl-2 color-black-45">
				<button
					:disabled="disabledClear"
					class="clear-button typo-body-2 border-0 bg-transparent color-black-45"
					@click.prevent="handleClear"
				>
					Clear all selected
				</button>
			</div>
		</div>
		<CButton
			:disabled="disabledCancel"
			color="tertiary"
			class="modal-button transparent mr-3"
			@click="handleCancel"
		>
			Cancel
		</CButton>
		<CButton
			:disabled="disabledConfirm"
			color="primary"
			class="modal-button transparent"
			@click="handleConfirm"
		>
			Done
		</CButton>
	</div>
</template>

<script>
export default {
	name: 'BaseModalFooter',

	props: {
		totalSelect: {
			type: Number,
			default: () => null,
		},
		disabledConfirm: {
			type: Boolean,
			default: false,
		},
		disabledCancel: {
			type: Boolean,
			default: false,
		},
		disabledClear: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isShowClear() {
			return this.$listeners.onClear;
		},
	},
	methods: {
		handleClear() {
			this.$emit('onClear');
		},
		handleCancel() {
			this.$emit('onCancel');
		},
		handleConfirm() {
			this.$emit('onConfirm');
		},
	},
};
</script>


<style lang="scss" scoped>
	.clear-action {
		border-left: 1px solid $color-gray-300;

		.clear-button {
			&:focus {
				outline: none;
			}
		}
	}
</style>
