<template>
	<div>
		<CForm class="d-flex align-items-start" @submit.prevent="handleSubmit">
			<CInput
				v-model.trim="inputSKU"
				:is-valid="isValid && null"
				:label="label"
				:placeholder="placeholder"
				:description="$t('global.seperateBySemicolon')"
				class="flex-fill"
				add-input-classes="form-get-sku-input"
				data-test-id="input-sku"
			>
				<template #invalid-feedback>
					<div
						v-if="!isValid"
						class="invalid-feedback"
						data-test-id="error-item-invalid"
					>
						{{ invalidFeedback }}
					</div>
				</template>
			</CInput>
			<CButton
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="button-add"
			>
				{{ actionButtonText }}
			</CButton>
		</CForm>
		<div
			v-if="!skuList.length"
			class="no-item-container"
		>
			<slot name="no-item"></slot>
		</div>
		<div
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<h6 class="typo-body-2">
				{{ listTitle }}
			</h6>
			<p class="show-text">
				{{ showText }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FormGetProductBySkuList',
	props: {
		label: {
			type: String,
			default: null,
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Paste or type SKU number here',
		},
		actionButtonText: {
			type: String,
			default: 'Add SKU',
		},
		listTitle: {
			type: String,
			default: 'SKU',
		},
	},
	data() {
		return {
			inputSKU: '',
		};
	},
	computed: {
		showText() {
			return this.skuList.join('; ');
		},
	},
	methods: {
		resetState() {
			this.inputSKU = '';
		},
		getListFromInputSKU(inputSKU) {
			let list = inputSKU.split(';');

			// trim all items and remove null
			list = list.map((item) => item.trim()).filter((item) => item);

			// remove duplicates
			return [...new Set(list)];
		},
		async handleSubmit() {
			if (!this.inputSKU) {
				return;
			}

			const mergedInput = `${this.showText}; ${this.inputSKU}`;
			const skuList = this.getListFromInputSKU(mergedInput);
			this.$emit('onGetProduct', skuList);
			this.resetState();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-sku-input {
		margin-right: rem(100);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-text {
		max-height: rem(272);
		font-size: rem($font-size-caption);
		font-weight: $font-weight-semi-bold;
		color: $color-black-45;
		margin: 0;
		overflow-x: hidden;
		overflow-wrap: break-word;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}

	.no-item-container {
		display: flex;
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}
</style>
