<template>
	<div class="label-list-wrapper">
		<ul v-if="list.length" class="label-list">
			<li
				v-for="item in list"
				:key="item.id"
				class="label-item"
			>
				<ModalProductLabelListItem
					:data="item"
					@onSelect="(data) => $emit('onSelect', data)"
				/>
			</li>
		</ul>
		<div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
			<img
				src="/assets/images/product-placeholder.svg"
				alt="Empty image"
				width="64"
				class="empty-image"
			>
			<h6 class="mb-1">
				{{ $t("global.searchNotFound", { field: "labels" }) }}
			</h6>
		</div>
	</div>
</template>

<script>
import ModalProductLabelListItem from '@/components/ModalProductLabelListItem.vue';

export default {
	name: 'ModalProductLabelList',

	components: {
		ModalProductLabelListItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-list-wrapper {
		height: rem(332);
		padding: rem(24);
		overflow-y: auto;
	}

	.label-list {
		padding: 0;
		margin: rem(-8);
		list-style: none;
	}

	.label-item {
		max-width: 16.6666666667%;
		display: inline-block;
		padding: rem(8);
	}

	.empty-image {
		margin-bottom: rem(12);
	}
</style>
